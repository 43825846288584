import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { getToken } from "utils/getToken";
import { formatToDate } from "utils/formatTime";
import { toast } from "react-toastify";

const CreateSubscriptionForm = ({ onClose }) => {
  const [token, setToken] = useState(getToken());

  // State to store the form data and track success status
  const [newSubscriptionData, setNewSubscriptionData] = useState({
    mobile: "",
    plan: "PREVIEW",
    startDate: new Date(),
    endDate: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000), // Set end date to 2 days from today
    maxCalls: "0",
    paymentDetails: {
      amount: 0,
      sameAsMobile: false,
      paymentNumber: "",
    },
    reason: "", // Reason for the subscription
  });

  // State to track if the subscription is created successfully
  const [isSubscriptionCreated, setIsSubscriptionCreated] = useState(false);
  const [isReasonFilled, setIsReasonFilled] = useState(true);
  // State to track the payment amount error message
  const [paymentAmountError, setPaymentAmountError] = useState("");

  // Handler for saving the new subscription
  const handleSaveNewSubscription = async () => {
    // Check if the reason field is filled
    if (!newSubscriptionData.reason) {
      setIsReasonFilled(false);
      return; // Return early if the reason field is empty
    } else {
      setIsReasonFilled(true);
    }

    try {
      // Prepare the data to be sent in the request
      const requestData = JSON.stringify({
        mobile: newSubscriptionData.mobile,
        plan_name: newSubscriptionData.plan,
        start_date: newSubscriptionData.startDate.toISOString(),
        end_date: newSubscriptionData.endDate.toISOString(),
        max_calls: newSubscriptionData.maxCalls,
        payment_number: newSubscriptionData.paymentDetails.sameAsMobile
          ? newSubscriptionData.mobile
          : newSubscriptionData.paymentDetails.paymentNumber
          ? newSubscriptionData.paymentDetails.paymentNumber
          : null,
        payment_amount: newSubscriptionData.paymentDetails.amount,
        reason: newSubscriptionData.reason,
      });
      console.log("Request Data:", requestData); // Add this line to see the request data

      // API request configuration
      const config = {
        method: "post",
        url:
          process.env.REACT_APP_API_URL +
          "subscription/support/create_subscription/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        data: requestData,
      };

      // Make the API request
      const response = await axios(config);

      // Check the response for success and log a message
      if (response.data) {
        console.log(
          "New subscription is created for " + newSubscriptionData.mobile
        );
        setIsSubscriptionCreated(true); // Set success status to show the success message
      }
    } catch (error) {

      if (error.response?.data?.message) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error("Something went wrong!");
      }

      if (
        error.response &&
        error.response.data &&
        error.response.data.error_fields
      ) {
        const errorFields = error.response.data.error_fields;
        if (errorFields.payment_amount) {
          console.error(
            "Error creating the subscription:",
            errorFields.payment_amount
          );
          setPaymentAmountError(errorFields.payment_amount); // Set the payment amount error message
        } else {
          console.error(
            "Error creating the subscription:",
            error.response.data
          );
        }
      } else {
        console.error("Error creating the subscription:", error.message);
      }
    }
  };

  // List of available plan options
  const planOptions = [
    "PREVIEW",
    "OLD_BASIC",
    "OLD_PRIME",
    "OLD_PREMIUM",
    "COMPLEMENTARY_CHAT",
    "BASIC_CHAT",
    "FREE_PREVIEW",
    "YOGA_BASIC",
    "HOLISTIC_OLD",
    "HOLISTIC_NEW",
    "BASIC",
    "PRIME",
    "ESSENTIAL",
    "Toilet Training Program TTP (1)",
    "BTP",
    "WTP",
    "STP",
  ];

  return (
    <div>
      {/* Modal backdrop */}
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto">
        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
        {/* Modal content */}
        <div className="relative z-10 w-full mx-4 bg-white rounded md:mx-auto md:max-w-lg">
          <div className="container max-w-md px-8 py-8 mx-auto bg-white rounded-lg md:px-0">
            {isSubscriptionCreated ? (
              // Show success message if the subscription is created
              <div className="p-8 text-center">
                <p className="mb-4 text-lg font-semibold">
                  New subscription is created for {newSubscriptionData.mobile}
                </p>
                <button
                  onClick={() => setIsSubscriptionCreated(false)}
                  className="px-8 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                >
                  Create Another Subscription
                </button>
              </div>
            ) : (
              // Show subscription creation form
              <div>
                <h2 className="mb-6 text-2xl font-bold">Create Subscription</h2>
                <div>
                  {/* Mobile Number */}
                  <div className="mb-2 md:mb-4">
                    <label className="block mb-1 font-semibold text-gray-700 md:mb-2">
                      Mobile Number:
                    </label>
                    <input
                      type="text"
                      value={newSubscriptionData.mobile}
                      onChange={(e) =>
                        setNewSubscriptionData({
                          ...newSubscriptionData,
                          mobile: e.target.value,
                        })
                      }
                      className="w-full px-4 py-2 border border-gray-400 rounded-md focus:border-blue-500 focus:outline-none"
                      placeholder="Enter mobile number"
                    />
                  </div>

                  {/* Plan */}
                  <div className="mb-2 md:mb-4">
                    <label className="block mb-1 font-semibold text-gray-700 md:mb-2">
                      Plan:
                    </label>
                    <select
                      value={newSubscriptionData.plan}
                      onChange={(e) =>
                        setNewSubscriptionData({
                          ...newSubscriptionData,
                          plan: e.target.value,
                        })
                      }
                      className="w-full px-4 py-2 border border-gray-400 rounded-md focus:border-blue-500 focus:outline-none"
                    >
                      {planOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex space-x-4">
                    {/* Start Date */}
                    <div className="mb-2 md:mb-4">
                      <label className="block mb-1 font-semibold text-gray-700 md:mb-2">
                        Start Date:
                      </label>
                      <DatePicker
                        selected={newSubscriptionData.startDate}
                        onChange={(date) =>
                          setNewSubscriptionData({
                            ...newSubscriptionData,
                            startDate: date,
                          })
                        }
                        dateFormat="dd/MM/yyyy"
                        className="w-full px-4 py-2 border border-gray-400 rounded-md focus:border-blue-500 focus:outline-none"
                      />
                      <p className="pl-2 font-bold text-blue-800">
                        {formatToDate(newSubscriptionData.startDate)}
                      </p>
                    </div>

                    {/* End Date */}
                    <div className="mb-2 md:mb-4">
                      <label className="block mb-1 font-semibold text-gray-700 md:mb-2">
                        End Date:
                      </label>
                      <DatePicker
                        selected={newSubscriptionData.endDate}
                        onChange={(date) =>
                          setNewSubscriptionData({
                            ...newSubscriptionData,
                            endDate: date,
                          })
                        }
                        dateFormat="dd/MM/yyyy"
                        className="w-full px-4 py-2 border border-gray-400 rounded-md focus:border-blue-500 focus:outline-none"
                      />
                      <p className="pl-2 font-bold text-blue-800">
                        {formatToDate(newSubscriptionData.endDate)}
                      </p>
                    </div>
                  </div>

                  {/* Max Calls */}
                  <div className="mb-2 md:mb-4">
                    <label className="block mb-1 font-semibold text-gray-700 md:mb-2">
                      Max Calls:
                      <span className="text-lg font-bold text-red-600">*</span>
                    </label>

                    <input
                      type="text"
                      value={newSubscriptionData.maxCalls}
                      onChange={(e) =>
                        setNewSubscriptionData({
                          ...newSubscriptionData,
                          maxCalls: e.target.value,
                        })
                      }
                      className="w-full px-4 py-2 border border-gray-400 rounded-md focus:border-blue-500 focus:outline-none"
                      placeholder="Enter max calls"
                    />
                  </div>

                  {/* Payment Details */}
                  <div className="mb-2 md:mb-4">
                    {/* Amount */}
                    <label className="block mb-1 font-semibold text-gray-700 md:mb-2">
                      Payment Amount:
                      <span className="pl-1 font-bold text-red-500">
                        (INR(₹))
                      </span>
                    </label>
                    <div className="flex flex-col items-center mb-4">
                      <input
                        type="number"
                        value={newSubscriptionData.paymentDetails.amount}
                        onChange={(e) =>
                          setNewSubscriptionData({
                            ...newSubscriptionData,
                            paymentDetails: {
                              ...newSubscriptionData.paymentDetails,
                              amount: e.target.valueAsNumber,
                            },
                          })
                        }
                        min="0"
                        placeholder="Enter amount"
                        className="w-full px-4 py-2 mr-2 border border-gray-400 rounded-md focus:border-blue-500 focus:outline-none"
                      />
                      {/* Display the payment amount error message */}
                      {paymentAmountError && (
                        <p className="mt-1 text-sm text-red-500">
                          {paymentAmountError}
                        </p>
                      )}
                    </div>
                    <label className="block mb-1 font-semibold text-gray-700 md:mb-2">
                      Payment Mobile Number :
                    </label>
                    {/* Payment Number */}
                    <div className="flex items-center mb-1 ">
                      <input
                        type="text"
                        value={newSubscriptionData.paymentDetails.paymentNumber}
                        onChange={(e) =>
                          setNewSubscriptionData({
                            ...newSubscriptionData,
                            paymentDetails: {
                              ...newSubscriptionData.paymentDetails,
                              paymentNumber: e.target.value,
                            },
                          })
                        }
                        placeholder="Enter payment number"
                        className="w-full px-4 py-2 mr-2 border border-gray-400 rounded-md focus:border-blue-500 focus:outline-none"
                      />
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={
                          newSubscriptionData.paymentDetails.sameAsMobile
                        }
                        onChange={(e) =>
                          setNewSubscriptionData({
                            ...newSubscriptionData,
                            paymentDetails: {
                              ...newSubscriptionData.paymentDetails,
                              sameAsMobile: e.target.checked,
                              paymentNumber: e.target.checked
                                ? newSubscriptionData.mobile
                                : "", // Prefill or reset payment number based on checkbox
                            },
                          })
                        }
                        className="w-5 h-5 text-blue-600 form-checkbox focus:border-blue-500 focus:outline-none"
                      />
                      <label className="ml-2 text-gray-700">
                        Use the Same Number as Mobile Number for Payment
                      </label>
                    </div>
                  </div>

                  {/* Reason */}
                  <div className="mb-2 md:mb-4">
                    <label className="block mb-2 font-semibold text-gray-700">
                      Reason:
                    </label>
                    <input
                      type="text"
                      value={newSubscriptionData.reason}
                      onChange={(e) =>
                        setNewSubscriptionData({
                          ...newSubscriptionData,
                          reason: e.target.value,
                        })
                      }
                      className="w-full px-4 py-2 border border-gray-400 rounded-md focus:border-blue-500 focus:outline-none"
                      placeholder="Enter reason for the subscription"
                    />
                    {/* Error message for empty reason */}
                    {!isReasonFilled && (
                      <p className="mt-1 text-sm text-red-500">
                        Reason is required.
                      </p>
                    )}
                  </div>
                  {/* Save and Cancel buttons */}
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={handleSaveNewSubscription}
                      className="px-8 py-2 mr-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
                    >
                      Save
                    </button>
                    <button
                      onClick={onClose} // Close the modal when Cancel is clicked
                      className="px-8 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Close icon */}
            <div
              className="absolute top-0 right-0 m-3 transition duration-150 ease-in-out cursor-pointer text-black-800 dark:text-black-100"
              onClick={onClose} // Close the modal when the close icon is clicked
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Close"
                className="icon icon-tabler icon-tabler-x"
                width={20}
                height={20}
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1={18} y1={6} x2={6} y2={18} />
                <line x1={6} y1={6} x2={18} y2={18} />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSubscriptionForm;
