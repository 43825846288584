import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import axios from "axios";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import SyncLoader from "components/Loaders/SyncLoader";
import {
  supportTaskModalState,
  isTasksUpdatedState,
} from "store/supportTasks/atoms/supportTasksState";
import { IoMdClose } from "react-icons/io";
import { getToken } from "utils/getToken";

const CreateTaskModal = () => {
  const [token, setToken] = useState(getToken());
  const [supportTaskModal, setSupportTaskModal] = useRecoilState(
    supportTaskModalState
  );
  const [isTasksUpdated, setIsTasksUpdated] =
    useRecoilState(isTasksUpdatedState);
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    description: supportTaskModal?.taskDetails?.description || "",
    scheduled_at: supportTaskModal?.taskDetails?.scheduled_at || "",
    remarks: "",
    user_name: supportTaskModal?.taskDetails?.user_name || "",
    mobile: supportTaskModal?.taskDetails?.mobile || "",
    status: supportTaskModal?.taskDetails?.status || "",
  });

  const selectedTaskId = supportTaskModal?.taskId;
  const selectedTaskDeatils = supportTaskModal?.taskDetails;
  const tasksRemarkHistory = supportTaskModal?.taskDetails?.remarks || [];
  const taskType = supportTaskModal?.taskDetails?.type;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };

  const handleUpdateTaskDetails = async (e) => {
    e.preventDefault();

    if (!formValues.scheduled_at) {
      return toast.error("Enter a scheduled_at time for the task!");
    }

    if (selectedTaskId) {
      try {
        setLoading(true);

        const config = {
          method: "post",
          url: process.env.REACT_APP_API_URL + "tasks/edit/",
          headers: headers,
          data: {
            task_id: selectedTaskId,
            scheduled_at: formValues.scheduled_at,
            user_name: formValues.user_name,
            mobile: formValues.mobile,
            status: formValues.status,
            remarks: formValues.remarks,
          },
        };

        // Update the data on the server using Axios
        const updateTaskResponse = await axios(config);

        if (updateTaskResponse.status === 200) {
          toast.success("Successfully Updated Task!");
          setIsTasksUpdated(true);
          handleCloseModal();
        }
      } catch (error) {
        console.error("UPDATE_TASK_DETAILS_ERROR", error);
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong while updating task!");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCreateTask = async (e) => {
    e.preventDefault();

    if (formValues.mobile) {
      try {
        setLoading(true);

        const config = {
          method: "post",
          url: process.env.REACT_APP_API_URL + "tasks/add/",
          headers: headers,
          data: {
            description: formValues.description,
            scheduled_at: formValues.scheduled_at,
            remarks: formValues.remarks,
            type: "custom_task",
            user_name: formValues.user_name,
            mobile: formValues.mobile,
          },
        };

        const createTaskResponse = await axios(config);

        if (createTaskResponse.status === 200) {
          toast.success("Task is created successfully!");
          setIsTasksUpdated(true);
          handleCloseModal();
        }
      } catch (error) {
        console.error("CREATE_SUPPORT_TASK_ERROR", error);
        toast.error("Something went wrong while creating task!");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please enter usermobile.");
    }
  };

  const getEditTaskForm = () => {
    return (
      <div>
        <form onSubmit={handleUpdateTaskDetails} className="mt-4 space-y-4">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {/* <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">
                Task Description
              </p>
              <input
                value={formValues.description}
                required
                onChange={(e) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    };
                  })
                }
                placeholder="Enter a description for this task"
                className="w-full px-3 py-2 bg-gray-100 border border-gray-100 rounded-md"
              />
            </div> */}

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Parent's Mobile</p>
              <input
                readOnly={taskType !== "custom_task"}
                value={formValues.mobile}
                onChange={(e) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      mobile: e.target.value,
                    };
                  })
                }
                placeholder="Enter user's mobile number"
                className="w-full px-3 py-2 bg-gray-100 border border-gray-100 rounded-md"
              />
            </div>

            <div className="w-full text-sm">
              <p className="font-semibold text-start">Status:</p>
              <select
                value={formValues.status}
                onChange={(e) => {
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      status: e.target.value,
                    };
                  });
                }}
                className={`w-full rounded-md bg-gray-100 p-3`}
              >
                <option value={"default"}>Choose the status</option>
                <option value={"done"}>Done</option>
                <option value={"pending"}>Pending</option>
              </select>
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">
                Task Due Date:<span className="text-red-500">*</span>
              </p>

              <input
                type="datetime-local"
                value={formValues.scheduled_at}
                onChange={(e) => {
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      scheduled_at: e.target.value,
                    };
                  });
                }}
                className={`w-full rounded-md border-gray-200 p-2`}
              />
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Task Remark:</p>
              <input
                value={formValues.remarks}
                onChange={(e) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      remarks: e.target.value,
                    };
                  })
                }
                placeholder="Enter a remark for this task"
                className="w-full px-3 py-2 bg-gray-100 border border-gray-100 rounded-md"
              />
            </div>

            {tasksRemarkHistory.length > 0 && (
              <div className="w-full text-sm">
                <p className="my-1.5 text-start font-semibold">
                  Remark History:
                </p>

                {tasksRemarkHistory.map((remark, index) => (
                  <div
                    key={index}
                    className="w-full px-3 py-2 my-2 bg-gray-100 border border-gray-100 rounded-md"
                  >
                    <p className="truncate">{remark.remark}</p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="mt-4">
            {loading ? (
              <div>
                <div className="px-4 py-2 text-white bg-blue-500 rounded-md">
                  <SyncLoader size="5px" />
                </div>
              </div>
            ) : (
              <button className="w-full px-4 py-2 text-white bg-blue-500 rounded-md">
                Update Task
              </button>
            )}
          </div>
        </form>
      </div>
    );
  };

  const getCreateTaskForm = () => {
    return (
      <div>
        <form onSubmit={handleCreateTask} className="mt-4 space-y-4">
          <div className="grid grid-cols-1 gap-6">
            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">
                Task Description:<span className="text-red-500">*</span>
              </p>
              <input
                value={formValues.description}
                required
                onChange={(e) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      description: e.target.value,
                    };
                  })
                }
                placeholder="Enter a description for this task"
                className="w-full px-3 py-2 bg-gray-100 border border-gray-100 rounded-md"
              />
            </div>

            {/* <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">User Name</p>
              <input
                value={formValues.user_name}
                required
                onChange={(e) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      user_name: e.target.value,
                    };
                  })
                }
                placeholder="Enter a name for user"
                className="w-full px-3 py-2 bg-gray-100 border border-gray-100 rounded-md"
              />
            </div> */}

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">
                Parent's Mobile:
              </p>
              <input
                value={formValues.mobile}
                required
                onChange={(e) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      mobile: e.target.value,
                    };
                  })
                }
                placeholder="Enter user's mobile number"
                className="w-full px-3 py-2 bg-gray-100 border border-gray-100 rounded-md"
              />
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">
                Task Due Date:<span className="text-red-500">*</span>
              </p>

              <input
                type="datetime-local"
                value={formValues.scheduled_at}
                required
                onChange={(e) => {
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      scheduled_at: e.target.value,
                    };
                  });
                }}
                className={`w-full rounded-md border-gray-600 p-2`}
              />
            </div>

            <div className="w-full text-sm">
              <p className="my-1.5 text-start font-semibold">Task Remark:</p>
              <input
                value={formValues.remarks}
                onChange={(e) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      remarks: e.target.value,
                    };
                  })
                }
                placeholder="Enter a remark for this task"
                className="w-full px-3 py-2 bg-gray-100 border border-gray-100 rounded-md"
              />
            </div>
          </div>

          <div className="mt-4">
            {loading ? (
              <div>
                <div className="px-4 py-2 text-white bg-blue-500 rounded-md">
                  <SyncLoader size="5px" />
                </div>
              </div>
            ) : (
              <button className="w-full px-4 py-2 text-white bg-blue-500 rounded-md">
                Create Task
              </button>
            )}
          </div>
        </form>
      </div>
    );
  };

  const handleCloseModal = () => {
    setSupportTaskModal({
      open: false,
      taskDetails: null,
      taskId: null,
      type: null,
    });

    setFormValues({
      description: "",
      scheduled_at: "",
      remarks: "",
      user_name: "",
      mobile: "",
      status: "",
    });
  };

  useEffect(() => {
    if (selectedTaskId) {
      setFormValues({
        description: supportTaskModal?.taskDetails?.description || "",
        scheduled_at: supportTaskModal?.taskDetails?.scheduled_at || "",
        remarks: "",
        user_name: supportTaskModal?.taskDetails?.user_name || "",
        mobile: supportTaskModal?.taskDetails?.mobile || "",
        status: supportTaskModal?.taskDetails?.status || "",
      });
    }
  }, [selectedTaskId]);

  console.log("Form Values", formValues);
  return (
    <Dialog
      open={supportTaskModal.open}
      onClose={() => {
        handleCloseModal();
      }}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-[#000] opacity-20" aria-hidden="true" />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 overflow-y-auto">
        {/* Container to center the panel */}
        <div className="flex items-center justify-center min-h-screen p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="mx-auto w-full max-w-md rounded md:min-w-[40%]">
            <div className="inline-block w-full p-6 my-8 bg-white shadow-xl">
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    handleCloseModal();
                  }}
                  className="text-gray-600 hover:text-gray-800"
                >
                  <IoMdClose size={25} />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-center text-gray-900"
              >
                {supportTaskModal.type === "add"
                  ? "Create Task"
                  : "Edit Task Details"}
              </Dialog.Title>
              {supportTaskModal.type
                ? supportTaskModal.type === "add"
                  ? getCreateTaskForm()
                  : getEditTaskForm()
                : null}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateTaskModal;
