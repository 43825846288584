import Growth from "../../../components/growth/growth.jsx";
import React from "react";

export default function index() {
  return (
    <>
      <Growth />
    </>
  );
}
