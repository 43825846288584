import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedUserState,
  showModalState,
  doctorsDataState,
  doctorIdState,
  appointmentDateState,
  appointmentTypeState,
  appointmentStatusState,
  remarkUpdateState,
  feedbackState,
  ratingState,
  currentPageState,
  filterChangeForUserState,
  filterSelectedForUserState,
  statusFilterState,
  filterSelectedForStatusState,
  mobileNumberState,
  isVideoConsultationAllowedState,
} from "store/appointment/atoms/appointmentAtomState";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { getToken } from "utils/getToken";
import { parseISO, format } from "date-fns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { useEffect, useState } from "react";

const appointmentStatusSelectOptions = [
  { name: "Created", value: "created" },
  { name: "Scheduled", value: "scheduled" },
  { name: "Pending Confirmation", value: "pending_confirmation" },
  { name: "On Going", value: "on_going" },
  { name: "Consultation Done", value: "consultation_done" },
  { name: "Cancelled", value: "cancelled" },
  { name: "Closed", value: "closed" },
];

const AppointmentModal = ({ handleCloseModal, fetchData }) => {
  const selectedUser = useRecoilValue(selectedUserState);
  const [showModal, setShowModal] = useRecoilState(showModalState);
  const doctors_Data = useRecoilValue(doctorsDataState);

  const [doctorsData, setDoctorsData] = useState(doctors_Data);

  const [doctorId, setDoctorId] = useRecoilState(doctorIdState);
  const [appointmentDate, setAppointmentDate] =
    useRecoilState(appointmentDateState);
  const [slotId, setSlotId] = useState("");
  const [slots, setSlots] = useState([]);
  const appointmentType = useRecoilValue(appointmentTypeState);
  const [appointmentStatus, setAppointmentStatus] = useRecoilState(
    appointmentStatusState
  );
  const mobileNumber = useRecoilValue(mobileNumberState);

  const [feedback, setFeedback] = useRecoilState(feedbackState);
  const [rating, setRating] = useRecoilState(ratingState);
  const [remarkUpdate, setRemarkUpdate] = useRecoilState(remarkUpdateState);
  const filterChangeForUser = useRecoilValue(filterChangeForUserState);
  const statusFilter = useRecoilValue(statusFilterState);
  const isFilterSelectedForUser = useRecoilValue(filterSelectedForUserState);
  const isFilterSelectedForStatus = useRecoilValue(
    filterSelectedForStatusState
  );

  const currentPage = useRecoilValue(currentPageState);
  const [isVideoConsultationAllowed, setIsVideoConsultationAllowed] =
    useRecoilState(isVideoConsultationAllowedState);

  const handleAppointmentStatusChange = (e) => {
    const { value } = e.target;
    setAppointmentStatus(value);
  };

  const handleSaveClick = async () => {
    console.log(doctorId);
    if (
      selectedUser.appointment_type === "growth" ||
      selectedUser.appointment_type.toLowerCase().includes("lactation")
    ) {
      console.log(typeof doctorId, doctorId, typeof slotId, slotId);
      if (doctorId === "0") {
        toast.error("Please select a doctor");
        console.log("called doctorId");
        return;
      }
      // if (slotId === "0" || slotId === "" || slotId === null) {
      //   console.log("called slotId");
      //   toast.error("Please select a slot");
      //   return;
      // }
    }

    const slot_id = parseInt(slotId);
    const updatedUser = {
      ...selectedUser,
      appointment_type: appointmentType,
      slot_id: slot_id,
      appointment_status: appointmentStatus,
    };

    console.log(updatedUser);

    if (
      selectedUser.appointment_type === "growth" ||
      selectedUser.appointment_type.toLowerCase().includes("lactation")
    ) {
      updatedUser.scheduled_at = appointmentDate;
    }

    if (updatedUser.appointment_status === "scheduled") {
      if (!selectedUser.patient_id || selectedUser.patient_id === "") {
        toast.error(
          "Please select a patient before scheduling the appointment"
        );
        setShowModal(false);
        return;
      }
    }

    try {
      const remarkUpdateData = remarkUpdate
        ? {
            email_id: localStorage.getItem("loginEmail"),
            time: format(new Date(), "dd-MM-yyyy HH:mm:ss"),
            note: remarkUpdate,
          }
        : {};

      let updateData = {
        appointment_id: updatedUser.id,
        appointment_status: updatedUser.appointment_status,
        appointment_type: updatedUser.appointment_type,
        remark: remarkUpdateData,
        rating: rating ? parseInt(rating) : null,
        feedback: feedback ? feedback : null,
        doctor_id: doctorId === 0 ? null : parseInt(doctorId),
        video_consultation_allowed: isVideoConsultationAllowed,
      };

      if (
        selectedUser.appointment_type === "growth" ||
        selectedUser.appointment_type.toLowerCase().includes("lactation")
      ) {
        updateData.slot_id = updatedUser.slot_id;
      } else {
        updateData.scheduled_at = appointmentDate;
      }

      const jsonData = JSON.stringify(updateData);

      console.log(updateData);

      const config = {
        method: "post",
        url: process.env.REACT_APP_API_URL + "consultation/appointment/update/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${getToken()}`,
        },
        data: jsonData,
      };

      const response = await axios(config);
      setRemarkUpdate("");
      setRating("");
      setFeedback("");
      setDoctorId(0);
      setShowModal(false);
      fetchData({
        mobileNumber,
        isFilterSelectedForUser,
        isFilterSelectedForStatus,
        filterChangeForUser,
        statusFilter,
        currentPage,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const FetchDoctorGrowthandLactation = async (category) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}doctors/doctors/all/?category=${category}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${getToken()}`,
          },
        }
      );
      console.log(data);
      setDoctorsData(data);
    } catch (error) {
      console.error("Error fetching doctors:", error);
      toast.error("Failed to fetch doctors");
    }
  };
  const fetchDoctorSlots = async (doctorId, slotType) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}doctors/get-doctor-appointment-slot/`,
        { doctor_id: doctorId, source: "support portal", slot_type: slotType },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${getToken()}`,
          },
        }
      );

      const slotData = data.appointments.map((slot) => ({
        value: slot.id,
        label: `${slot.date} (${dayjs(slot.time_from, "HH:mm").format(
          "hh:mm A"
        )} - ${dayjs(slot.time_to, "HH:mm").format("hh:mm A")})`,
      }));
      setSlots(slotData);
    } catch (error) {
      console.error("Error fetching doctor slots:", error);
      toast.error("Failed to fetch doctor slots");
    }
  };

  useEffect(() => {
    if (doctorId) {
      fetchDoctorSlots(doctorId, selectedUser.appointment_type);
    }
    if (selectedUser.appointment_type === "growth") {
      FetchDoctorGrowthandLactation("growth");
    } else if (
      selectedUser.appointment_type.toLowerCase().includes("lactation")
    ) {
      FetchDoctorGrowthandLactation("lactation");
    }
  }, [doctorId]);

  return (
    <div className="fixed inset-0 z-50 flex h-screen items-center justify-center overflow-y-auto text-sm sm:h-[150%]">
      <div className="absolute inset-0 h-[120%] bg-gray-900 pt-5 opacity-75 sm:h-[150%]"></div>
      <div className="relative z-10 self-center w-full mx-4 bg-white rounded top-10 md:mx-auto md:max-w-lg">
        <div className="p-4 md:p-8">
          <h2 className="text-2xl font-bold text-center">Appointment Data</h2>
          <div className="mt-2 space-y-4 md:mt-4">
            <div className="flex flex-col">
              <p className="font-semibold">
                ID:{" "}
                <span className="font-medium text-gray-700">
                  {selectedUser.id}
                </span>
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold">
                Phone Number:{" "}
                <span className="font-medium text-gray-700">
                  {selectedUser.phone_number}
                </span>
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold">
                User Name:{" "}
                <span className="font-medium text-gray-700">
                  {selectedUser.name}
                </span>
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold">
                Appointment Created:{" "}
                <span className="font-medium text-gray-700">
                  {format(
                    parseISO(selectedUser.created_at),
                    "dd-MM-yyyy HH:mm:ss"
                  )}
                </span>
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold">
                Appointment Updated: {"  "}
                <span className="font-medium text-gray-700">
                  {format(
                    parseISO(selectedUser.updated_at),
                    "dd-MM-yyyy HH:mm:ss"
                  )}
                </span>{" "}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold">
                Appointment Type:{" "}
                <span className="font-medium text-gray-700">
                  {appointmentType}
                </span>
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold">
                Subscription Name:{" "}
                <span className="font-medium text-gray-700">
                  {selectedUser.subscription_name}
                </span>
              </p>
            </div>

            <div className="flex flex-col">
              <p className="font-semibold">
                Doctor's Name:<b className="text-xl text-red-500">*</b>
              </p>
              <select
                value={doctorId}
                onChange={(e) => setDoctorId(e.target.value)}
                className="px-4 py-2 bg-white border border-gray-400 rounded-md"
                placeholder="Select a doctor"
              >
                <option value={0}>Select a doctor</option>
                {doctorsData.map((doctor, index) => (
                  <option key={index} value={doctor.id}>
                    {doctor.name}
                  </option>
                ))}
              </select>
            </div>

            {(selectedUser.appointment_type === "growth" ||
              selectedUser.appointment_type
                .toLowerCase()
                .includes("lactation")) && (
              <div className="flex flex-col">
                <p className="font-semibold">
                  Slots:<b className="text-xl text-red-500">*</b>
                </p>
                <select
                  value={slotId}
                  onChange={(e) => setSlotId(e.target.value)}
                  className="px-4 py-2 bg-white border border-gray-400 rounded-md"
                  placeholder="Select a slot"
                >
                  <option value={0}>Select a slot</option>
                  {slots.length > 0 ? (
                    slots.map((slot, index) => (
                      <option key={index} value={slot.value}>
                        {slot.label}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No slots available
                    </option>
                  )}
                </select>
              </div>
            )}

            <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8">
              <div className="flex flex-col">
                <p className="flex gap-1 font-semibold">
                  Appointment Date:
                  <b className="text-xl text-red-500">*</b>
                </p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={appointmentDate}
                    onChange={(date) => {
                      setAppointmentDate(date);
                      console.log(date);
                    }}
                    ampmInClock={false}
                    ampm={false}
                    format="DD-MM-YYYY HH:mm:ss"
                    disabled={
                      selectedUser.appointment_type === "growth" ||
                      selectedUser.appointment_type
                        .toLowerCase()
                        .includes("lactation")
                    }
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className="flex flex-col">
              <p className="flex gap-1 font-semibold ">
                Appointment Status: <b className="text-xl text-red-500">*</b>
              </p>
              <select
                value={appointmentStatus}
                onChange={handleAppointmentStatusChange}
                className="px-4 py-2 bg-white border border-gray-400 rounded-md"
              >
                {appointmentStatusSelectOptions.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold">Remark History:</p>
              <p
                className={`flex flex-col px-4 py-2 ${
                  selectedUser.remark.update &&
                  selectedUser.remark.update.length > 0
                    ? "rounded-md border"
                    : ""
                }`}
              >
                {selectedUser.remark.update &&
                  selectedUser.remark.update.length > 0 &&
                  selectedUser.remark.update.map((userRemark, index) => {
                    return (
                      <span key={index} className="font-medium text-gray-700">
                        {index + 1}. {userRemark.note} -{" by "}
                        <b className="text-gray-900">
                          {/* {userRemark.email_id.split(".")[0]} */}
                        </b>{" "}
                        {/* on {userRemark.time.split(" ")[0]} */}
                      </span>
                    );
                  })}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="flex gap-1 font-semibold">
                Remark:
                <b className="text-xl text-red-500">*</b>
              </p>
              <input
                value={remarkUpdate}
                onChange={(e) => {
                  if (e.target.value.trim() !== "")
                    setRemarkUpdate(e.target.value);
                }}
                type="text"
                className="px-4 py-2 border border-gray-400 rounded-md"
              />
            </div>

            <div className="flex flex-col">
              <p className="flex gap-1 font-semibold ">
                Feedback: <b className="text-xl text-red-500">*</b>
              </p>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="px-4 py-2 border border-gray-400 rounded-md resize-none"
              />
            </div>
            <div className="flex flex-row justify-between gap-4">
              <div className="flex flex-col justify-around w-full">
                <p className="flex gap-1 font-semibold ">
                  Video Consultation Allowed Via App:{" "}
                  <b className="text-xl text-red-500">*</b>
                </p>
                <select
                  value={isVideoConsultationAllowed}
                  onChange={(e) => {
                    setIsVideoConsultationAllowed(
                      e.target.value === "true" ? true : false
                    );
                  }}
                  className="px-4 py-2 bg-white border border-gray-400 rounded-md"
                >
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>

              <div className="flex flex-col">
                <p className="flex gap-1 font-semibold ">
                  Rating: <b className="text-xl text-red-500">*</b>
                </p>
                <select
                  value={rating}
                  onChange={(e) => {
                    setRating(e.target.value);
                  }}
                  className="px-4 py-2 bg-white border border-gray-400 rounded-md"
                >
                  <option value="null" disabled>
                    Select a Rating
                  </option>
                  <option value="1">Unsatisfied</option>
                  <option value="2">Satisfied</option>
                  <option value="3">Very Satisfied</option>
                </select>
              </div>
            </div>

            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 text-white bg-red-500 rounded-md"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                className="px-4 py-2 text-white bg-green-500 rounded-md"
                onClick={handleSaveClick}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentModal;
