import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken } from "utils/getToken";
import { FiSearch } from "react-icons/fi";
import { parseISO, format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "assets/css/filter.css";
import { HiExternalLink } from "react-icons/hi";
import { ClipLoader } from "react-spinners";
import "../../assets/css/Blink.css";
import { formatTimeInMinutes } from "utils/formatTimeInMinutes";
import ChatCountLogic from "../../utils/ChatCountLogic";
import IframeModal from "../ChatStatus/IframeModal";
import UserDataPopup from "components/UserData/UserDataPopup";

const ChatWootChatStatus = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPage, setFilterPage] = useState("");
  const [isPageLoading, setIsPageLoading] = useState("");

  // fetch initial data and group data
  const [res, setRes] = useState();

  async function fetchChats() {
    const token = getToken();
    try {
      let url =
        process.env.REACT_APP_API_URL + "whatsapp/get-chatwoot-open-chats/";
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      setRes(response?.data);
    } catch (error) {
      toast.error("Error fetching data from API");
      console.error(error);
    }
  }

  useEffect(() => {
    fetchChats();
  }, []);

  const handlePageSearch = () => {
    const pageNumber = parseInt(filterPage);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    setData([]);
    window.scrollTo(0, 0);
  };

  const timeAgo = (dateTime) => {
    const messageDate = new Date(dateTime);
    const now = new Date();
    const differenceInMinutes = Math.round((now - messageDate) / 60000); // 60000 milliseconds in a minute

    if (differenceInMinutes < 60) {
      return `${differenceInMinutes} minutes`;
    } else {
      // Handle hours or days if needed
      const differenceInHours = Math.round(differenceInMinutes / 60);
      if (differenceInHours < 24) {
        return `${differenceInHours} hours`;
      } else {
        const differenceInDays = Math.round(differenceInHours / 24);
        return `${differenceInDays} days`;
      }
    }
  };

  const currentTime = new Date();
  const [serverSyncTime, setServerSyncTime] = useState(currentTime);

  const timeDifference = serverSyncTime
    ? currentTime - new Date(serverSyncTime)
    : null;

  const isTimeDifferenceExceeded =
    timeDifference && timeDifference > 15 * 60 * 1000;

  return (
    <div className="mx-auto sm:w-full">
      <ChatCountLogic setServerSyncTime={setServerSyncTime} />
      <div className="flex items-center justify-center pt-2">
        <h1
          className={`text-lg font-semibold ${
            isTimeDifferenceExceeded
              ? "animate-blink text-red-500"
              : "text-green-500"
          }`}
        >
          {isTimeDifferenceExceeded && (
            <>
              <span className={` text-red-500`}>⚠️</span>
              Last message synced on server:{" "}
              {formatTimeInMinutes(serverSyncTime)}
              <span className="text-red-500 ">⚠️</span>
              Contact Tech Team.
            </>
          )}
          {!isTimeDifferenceExceeded && (
            <>
              Last message synced on server:{" "}
              {formatTimeInMinutes(serverSyncTime)}
            </>
          )}
        </h1>
      </div>
      {/* Live Chat section */}
      <div className="mb-5 py-6">
        {/* Header */}
        <div className="mr-1 mb-4 flex justify-between">
          <h1 className="items-center justify-center text-center text-2xl font-bold capitalize text-blue-500 dark:text-white">
            Chatwoot Chat Status
          </h1>

          <button
            className="rounded-lg bg-blue-500 px-8 py-2 text-white hover:bg-blue-600"
            onClick={() => fetchChats()}
          >
            Refresh
          </button>
        </div>

        {/* For desktop view */}
        {!isPageLoading ? (
          <table className="w-full bg-white shadow-md ">
            <thead>
              <tr className="bg-blue-200">
                <th className="border px-4 py-2">Message</th>
                <th className="border px-4 py-2">Phone Number</th>
                <th className="border px-2 py-2 ">Last Message Time</th>
                <th className="border px-4 py-2">Chat Link</th>
              </tr>
            </thead>
            <tbody>
              {res?.map((row, rowIndex) => (
                <tr key={rowIndex} className="hover:bg-blue-100">
                  <td className="w-[30%] border px-4 py-2">
                    {row.message.length > 200 ? (
                      <p>{row.message.slice(0, 200)}...</p>
                    ) : (
                      <p>{row.message}</p>
                    )}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {row.phone_number}
                  </td>
                  <td className="w-[15%] border px-2 py-2 text-center">
                    {format(parseISO(row.last_msg_time), "HH:mm")} {"("}
                    {row.last_msg_time
                      ? `${timeAgo(row.last_msg_time)} ago`
                      : "Not available"}
                    {")"}
                  </td>
                  <td className="border px-4 py-2">
                    <button
                      className="mx-auto flex cursor-pointer gap-x-2 whitespace-nowrap text-blue-500 hover:underline"
                      onClick={() => {
                        window.open(row.chat_link);
                      }}
                    >
                      <p>Open chat</p> <HiExternalLink />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : data.length > 0 ? (
          <div className="flex h-[70vh] items-center justify-center">
            <ClipLoader color={"blue"} size={60} />
          </div>
        ) : (
          <p className="text-center text-lg font-semibold">No data available</p>
        )}

        <div className="mt-10 flex flex-col justify-center md:mt-20 md:flex-row">
          <div className="flex justify-center md:ml-auto">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline-flex h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              Previous Page
            </button>

            <h3 className="flex items-center px-2 text-lg text-gray-800 ">
              Page {currentPage}
            </h3>

            <button
              // disabled={currentPage === totalPages}
              className="mx-2 rounded-lg bg-blue-500 px-4 py-4 text-white hover:bg-blue-600 md:px-8"
            >
              Next Page
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline-flex h-5 w-5 -rotate-180 transform"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="mt-5 flex items-center justify-end md:ml-auto md:mt-0">
            <input
              type="text"
              placeholder={`Go to page (1 - ${totalPages})`}
              value={filterPage}
              onChange={(e) => setFilterPage(e.target.value)}
              className="w-full rounded-md border border-gray-400 bg-white py-2 pl-8 pr-2 text-sm font-medium text-gray-800 outline-none"
            />
            <button
              className="mx-2 cursor-pointer rounded-lg bg-blue-500 px-4 py-3 text-white hover:bg-blue-300"
              onClick={handlePageSearch}
            >
              <FiSearch
                className="h-4 w-4 cursor-pointer rounded-lg"
                onClick={handlePageSearch}
              />
            </button>
          </div>
        </div>
      </div>
      <hr />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ChatWootChatStatus;
