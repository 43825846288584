import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { getToken } from "utils/getToken";
import { FiSearch } from "react-icons/fi";
import { parseISO, format } from "date-fns";
import { useMediaQuery } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "assets/css/filter.css";
import { HiExternalLink, HiOutlineSelector } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { ClipLoader } from "react-spinners";
import "../../assets/css/Blink.css";
import IframeModal from "../ChatStatus/IframeModal";
import SyncLoader from "../Loaders/SyncLoader";
import { Listbox } from "@headlessui/react";
import { formatToDateAndTime } from "utils/formatTime";
import UserDataPopup from "components/UserData/UserDataPopup";

const statusFilterOptions = [
  { value: "all", name: "All" },
  { value: "livechat", name: "Live Chat (Open, Active, Pending Doctor)" },
  { value: "closed", name: "Closed" },
  { value: "replied", name: "Replied" },
  { value: "blocked", name: "Blocked" },
];

const FollowUpChatTool = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState("All Groups");
  const [filterPage, setFilterPage] = useState("");
  const [isGroupDropdownOpen, setIsGroupDropdownOpen] = useState(false);
  const [groupSearchQuery, setGroupSearchQuery] = useState("");
  const [visibleGroups, setVisibleGroups] = useState(30);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [groups, setGroups] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [lastMessageTime, setLastMessageTime] = useState("gamma");
  const [statusFilter, setStatusFilter] = useState([
    "open",
    "active",
    "pending_doctor",
  ]);
  const [selectedStatusFilters, setSelectedStatusFilters] = useState([
    statusFilterOptions[1],
  ]);
  const [isChatStatusModalOpen, setIsChatStatusModalOpen] = useState(false);
  const [groupDataLoaded, setGroupDataLoaded] = useState(false);
  const [isChatStatusSaveButtonDisabled, setIsChatStatusSaveButtonDisabled] =
    useState(data.map(() => false));
  const [mobileNumber, setMobileNumber] = useState("");
  const [startChatMobileNumber, setStartChatMobileNumber] = useState("");
  const [startChatUserId, setStartChatUserId] = useState("");
  const [isFilterStatusMenuOpen, setIsFilterStatusMenuOpen] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);
  const [newStatus, setNewStatus] = useState("default");
  // const [chatCurrentStatus, setChatCurrentStatus] = useState("null");
  const [currentChatId, setCurrentChatId] = useState(null);
  const [chatIndex, setChatIndex] = useState(null);
  const [
    isChatStatusModalOpenSaveButttonDisable,
    setIsChatStatusModalOpenSaveButttonDisable,
  ] = useState(false);
  const [sortingMethod, setSortingMethod] = useState("asc");
  const [isChatStatusUpdating, setIsChatStatusUpdating] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;

  // const groupDataFetcher = async () => {
  //   try {
  //     let url = process.env.REACT_APP_API_URL + "whatsapp/get-group-details/";

  //     const response = await axios.get(url, {
  //       headers: {
  //         Authorization: `Token ${getToken()}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     // console.log("response", response);

  //     if (
  //       response.data &&
  //       response.data.data &&
  //       response.data.data.length > 0
  //     ) {
  //       setGroupDataLoaded(true);
  //       const groupNames = response.data.data.map((group) => group.group_name);

  //       // console.log("groupData", groupData);
  //       setGroups(["All Groups", ...groupNames]);
  //       setGroupData([
  //         {
  //           group_name: "All Groups",
  //           group_id: null,
  //         },
  //         ...response.data.data,
  //       ]);

  //       // setSelectedGroup(groups[0]);
  //       // console.log(groupData);
  //       // setSelectedGroupId(groupData[0].group_id);
  //     }
  //   } catch (error) {
  //     console.log("Error fetching data:", error);
  //     // setIsDisabled(false);
  //   }
  // };

  const getFollowUpChatsData = async (retries = 0) => {
    try {
      setIsPageLoading(true);
      const apiUrl =
        process.env.REACT_APP_API_URL + "live-chat/get-followup-chat/";

      let queryParams = {};

      let statusFiltersQuery = [];
      let formattedStatusFilters;

      if (selectedStatusFilters.length > 0) {
        statusFiltersQuery = selectedStatusFilters.map(
          (filter) => filter.value
        );

        if (statusFiltersQuery.includes("all")) {
          statusFiltersQuery = [];

          statusFiltersQuery.push(
            "active",
            "pending_doctor",
            "open",
            "closed",
            "replied",
            "blocked"
          );
        } else if (statusFiltersQuery.includes("livechat")) {
          statusFiltersQuery = statusFiltersQuery.filter(
            (filter) => filter !== "livechat"
          );
          statusFiltersQuery.push("active", "pending_doctor", "open");
        }
        formattedStatusFilters = `${statusFiltersQuery.join(",")}`;

        queryParams.chat_status =
          selectedStatusFilters.length > 0 ? formattedStatusFilters : null;
      }

      const timeInMinutes = parseInt(lastMessageTime, 10);
      if (retries >= 0 && !isNaN(timeInMinutes)) {
        queryParams.minutes = timeInMinutes;
      }
      if (selectedGroupId !== null) {
        queryParams.group_id = selectedGroupId;
      }
      if (sortingMethod !== null && sortingMethod !== "default") {
        queryParams.sort_by = sortingMethod;
      }
      if (startChatMobileNumber) {
        queryParams.phone_number = startChatMobileNumber;
      }
      if (filterChanged) {
        setCurrentPage(1);
      }
      if (currentPage) {
        queryParams.page = filterChanged ? 1 : currentPage;
      }
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
        params: queryParams,
      });
      if (filterChanged) {
        setFilterChanged(false);
      }
      if (response.status === 200) {
        if (response.data?.results?.chats?.length === 0) {
          toast.error("No data found for the selected filters.");
          setData([]);
          setCurrentPage(1);
          setTotalPages(0);
        } else {
          setData(response.data?.results?.chats);
          const totalItems = response?.data?.count;
          const itemsPerPage = 10;

          const totalPages = Math.ceil(totalItems / itemsPerPage);
          setTotalPages(totalPages);
        }
      } else {
        toast.error("Failed to fetch data from the API.");
      }
    } catch (error) {
      console.error("Error fetching followUpChatsData:", error);
      setData([]);
      setCurrentPage(1);
      setTotalPages(0);
      if (error?.response?.status === 404) {
        if (
          error?.response?.data?.error === "No user" ||
          error?.response?.data?.error === "No chats"
        ) {
          if (error?.response?.data?.user_id) {
            setStartChatUserId(error?.response?.data?.user_id);
          } else {
            setStartChatUserId("");
          }
        }
      } else {
        toast.error("Failed to Fetch Data, Something went wrong!");
      }
    } finally {
      setIsPageLoading(false);
    }
  };

  const handleFilterChange = (newSelectedStatuses) => {
    if (newSelectedStatuses.includes(statusFilterOptions[0])) {
      if (
        newSelectedStatuses.length === statusFilterOptions.length - 1 &&
        selectedStatusFilters.includes(statusFilterOptions[0])
      ) {
        setSelectedStatusFilters(
          newSelectedStatuses.filter((status) => status.value !== "all")
        );
        setFilterChanged(true);
      } else {
        setSelectedStatusFilters(statusFilterOptions);
        setFilterChanged(true);
      }
    } else if (
      !selectedStatusFilters.includes(statusFilterOptions[0]) &&
      newSelectedStatuses.length >= statusFilterOptions.length - 1
    ) {
      setSelectedStatusFilters(statusFilterOptions);
      setFilterChanged(true);
    } else if (
      selectedStatusFilters.includes(statusFilterOptions[0]) &&
      newSelectedStatuses.length >= statusFilterOptions.length - 1
    ) {
      setSelectedStatusFilters([]);
      setFilterChanged(true);
    } else {
      setSelectedStatusFilters(newSelectedStatuses);
      setFilterChanged(true);
    }
  };

  // fetch initial data and group data
  // useEffect(() => {
  //   groupDataFetcher();
  // }, []);

  useEffect(() => {
    getFollowUpChatsData();
  }, [
    currentPage,
    selectedGroupId,
    selectedStatusFilters,
    lastMessageTime,
    groupDataLoaded,
    sortingMethod,
    startChatMobileNumber,
  ]);

  // For filters

  // for mobile filter
  const handleSearchByMobileNumber = () => {
    if (startChatMobileNumber !== mobileNumber) {
      setStartChatMobileNumber(mobileNumber);
      setCurrentPage(1);
    }
  };

  const handleMobileNumberRemoval = () => {
    setMobileNumber("");
    setStartChatMobileNumber("");
    setCurrentPage(1); // Reset page when mobile number is removed
  };

  // For ascending/descending sort
  const handleSortChange = (value) => {
    setSortingMethod(value);
    setFilterChanged(true);
  };

  const handlePageSearch = () => {
    const pageNumber = parseInt(filterPage);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setMobileNumber("");
      setCurrentPage(pageNumber);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
    window.scrollTo(0, 0);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
    window.scrollTo(0, 0);
  };

  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const numberOfButtons = isSmallScreen ? 4 : 10;

  // For changing status
  const handleChatStatusChangeEdit = (chatId, currentStatus, rowIndex) => {
    setIsChatStatusModalOpen(true);
    setCurrentChatId(chatId);
    setChatIndex(rowIndex);
    setNewStatus(currentStatus);
  };

  const handleChatStatusChange = async (chatId, currentStatus, rowIndex) => {
    const url = process.env.REACT_APP_API_URL + "whatsapp/chat-update/";
    const payload = {
      chat_status: currentStatus,
      chat_id: chatId,
    };
    try {
      const updatedDisabledState = [...isChatStatusSaveButtonDisabled];
      updatedDisabledState[rowIndex] = true;
      setIsChatStatusSaveButtonDisabled(updatedDisabledState);
      setIsChatStatusUpdating(true);
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      // console.log(response);
      // console.log(response.data);
      if (response.status === 200) {
        const itemIndex = data.findIndex((item) => item.chat_id === chatId);
        console.log("itemIndex", itemIndex);

        if (itemIndex !== -1) {
          // Create a copy of the data array with the updated Chat_status
          const updatedData = [...data];
          updatedData[itemIndex].chat_status = response.data.updated_status;
          // Update the state with the new data
          setData(updatedData);
          updatedDisabledState[rowIndex] = false;
          setIsChatStatusSaveButtonDisabled(updatedDisabledState);
          setIsChatStatusModalOpen(false);
          toast.success("Chat status updated successfully");
        }
      }
    } catch (error) {
      console.log("Error updating data:", error);
      const updatedDisabledState = [...isChatStatusSaveButtonDisabled];

      updatedDisabledState[rowIndex] = false;
      setIsChatStatusSaveButtonDisabled(updatedDisabledState);
      toast.error("Error updating data:", error);
    } finally {
      setIsChatStatusUpdating(false);
    }
  };

  const timeAgo = (dateTime) => {
    const messageDate = new Date(dateTime);
    const now = new Date();
    const differenceInMinutes = Math.round((now - messageDate) / 60000); // 60000 milliseconds in a minute

    if (differenceInMinutes < 60) {
      return `${differenceInMinutes} minutes`;
    } else {
      // Handle hours or days if needed
      const differenceInHours = Math.round(differenceInMinutes / 60);
      if (differenceInHours < 24) {
        return `${differenceInHours} hours`;
      } else {
        const differenceInDays = Math.round(differenceInHours / 24);
        return `${differenceInDays} days`;
      }
    }
  };

  // For navigating to that particular chat
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const redirectToFollowUpChatMessages = (
    userId,
    chatId,
    chatStatus,
    rowIndex
  ) => {
    if (userId && chatId && chatStatus) {
      const url = `/admin/followup-chat-messages?userId=${userId}&chatId=${chatId}&status=${chatStatus}`;

      setHighlightedRow(rowIndex);
      setModalContent({ url });
    } else {
      if (userId) {
        const url = `/admin/followup-chat-messages?userId=${userId}`;

        setHighlightedRow(rowIndex);
        setModalContent({ url });
      }
    }
  };

  const closeModal = () => {
    setModalContent(null);
  };

  // For PData Modal

  const [selectedUser, setSelectedUser] = useState(null);
  const [isPDataModalOpen, setIsPDataModalOpen] = useState(false);
  const handlePDataModal = (row) => {
    setSelectedUser(row.Chat_started_by);
    // console.log(row.Chat_started_by);
    setIsPDataModalOpen(true);
  };

  const handlePDataModalClose = () => {
    setIsPDataModalOpen(false);
    setSelectedUser(null);
  };
  const refreshData = () => {
    getFollowUpChatsData();
  };

  const getActionButtonByChatStatus = (row, rowIndex) => {
    if (row.chat_status === "open" || row.chat_status === "active") {
      return (
        <div className="flex flex-col items-center justify-center gap-3 md:flex-row">
          <div className="">
            <div className="flex items-center justify-center gap-2">
              {isChatStatusSaveButtonDisabled[rowIndex] ? (
                <div className="px-2 py-2 text-sm font-semibold text-white bg-red-500 rounded-md">
                  <SyncLoader size="5px" />
                </div>
              ) : (
                <button
                  className="px-2 py-2 text-sm text-white bg-red-500 rounded-md hover:bg-red-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                  onClick={() =>
                    handleChatStatusChange(row.chat_id, "closed", rowIndex)
                  }
                  disabled={isChatStatusSaveButtonDisabled[rowIndex]}
                >
                  Close
                </button>
              )}
              <button
                className="px-3 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                onClick={() =>
                  handleChatStatusChangeEdit(
                    row.chat_id,
                    row.chat_status,
                    rowIndex
                  )
                }
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      );
    } else if (row.chat_status === "closed") {
      return (
        <div className="flex items-center justify-center gap-2 text-sm">
          <button
            className="px-3 py-2 text-white bg-green-500 rounded-md hover:bg-green-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
            onClick={() =>
              handleChatStatusChangeEdit(row.chat_id, row.chat_status, rowIndex)
            }
          >
            Edit
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="mx-auto sm:w-full">
      {modalContent && (
        <IframeModal onClose={closeModal} url={modalContent.url} />
      )}
      {/* Live Chat section */}
      <div className="py-6 mb-5">
        {/* Header */}
        <div className="flex justify-between mr-1">
          <h1 className="items-center justify-center text-2xl font-bold text-center text-blue-500 capitalize dark:text-white">
            Follow Up Chat Status
          </h1>
          <button
            className="px-8 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
            onClick={() => refreshData()}
          >
            Refresh
          </button>
        </div>

        {/* Filters */}
        <div className="flex flex-col py-4 md:flex-row md:justify-between ">
          {/* Filters via chat status */}
          <div className="">
            {/* <span className="mr-2">Filter by Status:</span> */}
            <Listbox
              value={selectedStatusFilters}
              onChange={handleFilterChange}
              multiple
            >
              {({ open }) => (
                <>
                  <div className="relative inline-block text-left">
                    <Listbox.Button className="inline-flex items-center justify-center w-full gap-4 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                      {selectedStatusFilters.length > 0
                        ? `${selectedStatusFilters.length} Filter Selected`
                        : "Filter By Status"}
                      <HiOutlineSelector size="22" />
                    </Listbox.Button>
                  </div>
                  <Listbox.Options
                    className="absolute mt-1 max-h-60 min-w-[180px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
                    style={{ display: open ? "block" : "none" }}
                  >
                    {statusFilterOptions.map((option, index) => (
                      <Listbox.Option
                        key={index}
                        value={option}
                        className={({ active }) =>
                          `${
                            active ? "bg-blue-500 text-white" : "text-gray-900"
                          }
                        relative cursor-pointer select-none py-2 pl-1 pr-4`
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center gap-3">
                              <input
                                type="checkbox"
                                readOnly
                                checked={selected ? true : false}
                                className="cursor-pointer"
                              />
                              <span
                                className={`${
                                  selected ? "font-semibold" : "font-normal"
                                }`}
                              >
                                {option.name}
                              </span>
                            </div>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </>
              )}
            </Listbox>
          </div>

          {/* Filter by Last Message Time */}
          {/* <div className="relative inline-flex items-center">
            <select
              id="lastMessageTime"
              className={`focus:ring-primary rounded-md border border-gray-300 bg-white px-4 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1 ${
                lastMessageTime !== "gamma" && lastMessageTime !== ""
                  ? "text-green-500"
                  : "text-black"
              }`}
              value={lastMessageTime}
              onChange={(e) => {
                handleLastMessageTimeChange(e.target.value);
              }}
            >
              <option value="gamma">Filter By Last Message Time</option>
              <option value="">All Time</option>
              <option
                value="5 mins"
                className={
                  lastMessageTime === "5 mins" ? "text-green-500" : "text-black"
                }
              >
                5 mins ago
              </option>
              <option
                value="10 mins"
                className={
                  lastMessageTime === "10 mins"
                    ? "text-green-500"
                    : "text-black"
                }
              >
                10 mins ago
              </option>
              <option
                value="15 mins"
                className={
                  lastMessageTime === "15 mins"
                    ? "text-green-500"
                    : "text-black"
                }
              >
                15 mins ago
              </option>
              <option
                value="30 mins"
                className={
                  lastMessageTime === "30 mins"
                    ? "text-green-500"
                    : "text-black"
                }
              >
                30 mins ago
              </option>
            </select>
            {lastMessageTime && lastMessageTime !== "gamma" && (
              <button
                className="z-50 ml-2 text-red-500 cursor-pointer"
                onClick={() => setLastMessageTime("gamma")}
              >
                <MdCancel />
              </button>
            )}
          </div> */}

          {/* Ascending/ descending filter */}
          <div className="relative inline-flex items-center">
            <select
              id="ascendingDescending"
              className={`focus:ring-primary rounded-md border border-gray-300 bg-white px-4 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1 ${
                sortingMethod !== "default" && sortingMethod !== null
                  ? "text-green-500"
                  : "text-black"
              }`}
              value={sortingMethod}
              onChange={(e) => {
                handleSortChange(e.target.value);
              }}
            >
              <option value="default">Choose sorting format</option>
              <option
                value="asc"
                className={
                  sortingMethod === "asc" ? "text-green-500" : "text-black"
                }
              >
                Ascending
              </option>
              <option
                value="desc"
                className={
                  sortingMethod === "desc" ? "text-green-500" : "text-black"
                }
              >
                Descending
              </option>
            </select>
            {sortingMethod && sortingMethod !== "default" && (
              <button
                className="z-50 ml-2 text-red-500 cursor-pointer"
                onClick={() => setSortingMethod("default")}
              >
                <MdCancel />
              </button>
            )}
          </div>

          {/* Filters via mobile number */}
          <div
            id="dropdown"
            className="flex w-[25vw] items-stretch rounded-xl bg-white p-1"
          >
            <input
              type="tel"
              className="border-neutral-300 bg-transparent text-neutral-700 focus:border-primary focus:text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary relative block w-[1px] min-w-0 flex-auto rounded-l border border-solid bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none"
              id="mobile"
              placeholder="Search By Mobile Number"
              aria-label="Search"
              value={mobileNumber}
              onChange={(e) => {
                if (e.target.value === "") {
                  handleMobileNumberRemoval();
                } else {
                  const mobile = e.target.value.replace(/[^\d]/g, "");
                  setMobileNumber(mobile);
                }
              }}
            />
            <button
              className="flex items-center px-4 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-red-500 shadow-md active:bg-primary-800 hover:bg-red-300 hover:shadow-lg focus:bg-red-300 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
              type="button"
              id="button-addon1"
              data-te-ripple-init
              data-te-ripple-color="light"
              onClick={() => {
                handleMobileNumberRemoval();
              }}
            >
              <MdCancel size={20} />
            </button>
            <div className="flex">
              <button
                className="active:bg-primary-800 flex items-center rounded-r bg-[#113f6ec6] px-6 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                type="button"
                id="button-addon1"
                onClick={() => handleSearchByMobileNumber()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Filters end */}

        {isChatStatusModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>
            <div className="z-50 mx-auto bg-white rounded shadow-lg modal-container w-96">
              <div className="p-6 modal-content">
                <button
                  className="relative left-[330px] text-gray-600 hover:text-gray-800"
                  onClick={() => setIsChatStatusModalOpen(false)}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
                <h2 className="mb-4 text-lg font-semibold">Change Status</h2>
                <select
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  className="w-full p-2 border rounded-md"
                >
                  <option value="default">Choose the status</option>
                  <option value="closed">Closed</option>
                  <option value="active">Active</option>
                  <option value="replied">Replied</option>
                  <option value="open">Open</option>
                </select>
                <div className="flex justify-end mt-4">
                  {isChatStatusUpdating ? (
                    <div className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500">
                      <SyncLoader size="5px" />
                    </div>
                  ) : (
                    <button
                      className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                      onClick={() => {
                        handleChatStatusChange(
                          currentChatId,
                          newStatus,
                          chatIndex
                        );
                      }}
                      disabled={isChatStatusUpdating}
                    >
                      Save
                    </button>
                  )}

                  <button
                    className="px-4 py-2 ml-2 text-white bg-gray-400 rounded-md"
                    onClick={() => setIsChatStatusModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <PData
          mobile={selectedUser}
          isPDataModalOPen={isPDataModalOpen}
          onClose={() => {
            handlePDataModalClose();
          }}
        />
         */}

        {/* new way to show user data in modal */}
        {isPDataModalOpen && (
          <UserDataPopup
            selectedUser={selectedUser}
            noSearch={true}
            handlePDataModalClose={handlePDataModalClose}
          />
        )}

        {/* For mobile view */}
        {/* <div className="mx-auto sm:hidden">
        {!isPageLoading ? (
          data.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className="p-4 mb-4 bg-white rounded-lg shadow-lg"
            >
              <div className="mb-2">
                <p className="text-base font-semibold">{row.Group_name}</p>
              </div>
              <div className="text-black mt-2 h-auto w-full rounded-lg border-2 border-solid border-[#BFDBFE] bg-white py-2 px-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-600">
                <div className="flex justify-between pb-2">
                  <p className="text-xs text-blue-800">
                    User_id ~ {row.User_id}
                  </p>
                  <div className="flex flex-col">
                    <p className="text-sm text-blue-500">
                      ~ {row.Chat_started_by}
                    </p>
                    {row.Chat_start_time ? (
                      <p>
                        {format(
                          parseISO(row.Chat_start_time),
                          "dd-MM-yyyy HH:mm:ss"
                        )}
                      </p>
                    ) : (
                      <p className="text-gray-500">Not available</p>
                    )}
                  </div>
                </div>
                <p className="">{row.message}</p>
                <div className="flex flex-col justify-between pb-2">
                  <p
                    className={`mt-2  text-sm capitalize ${
                      row.Chat_status === "active"
                        ? "text-blue-500 "
                        : row.Chat_status === "replied"
                        ? "text-green-500 "
                        : row.Chat_status === "closed"
                        ? "text-gray-500 "
                        : "text-red-500 "
                    }`}
                  >
                    {row.Chat_status}
                  </p>
                  <button
                    className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                    onClick={() =>
                      handleChatStatusChange(row.id, row.Chat_status, rowIndex)
                    }
                    disabled={isChatStatusSaveButtonDisabled}
                  >
                    {isChatStatusSaveButtonDisabled[rowIndex]
                      ? "Please Wait..."
                      : "Close Chat"}
                  </button>

                  <div className="flex flex-col mt-4">
                    {row.Chat_last_updated ? (
                      <p className="text-xs">
                        {format(
                          parseISO(row.Chat_last_updated),
                          "dd-MM-yyyy HH:mm:ss"
                        )}
                      </p>
                    ) : (
                      <p className="text-sm text-red-500">Not replied</p>
                    )}
                  </div>
                  <button
                    className="text-blue-500 hover:cursor-pointer"
                    onClick={() => {
                      createLinkAndOpenChat(row, rowIndex);
                    }}
                  >
                    Open chat <HiExternalLink />
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-lg font-semibold text-center">No data available</p>
        )}
      </div> */}

        {/* For desktop view */}
        {!isPageLoading && data.length > 0 ? (
          <div className="min-h-[60vh] max-w-full overflow-x-auto">
            <table className="w-full bg-white shadow-md ">
              <thead>
                <tr className="bg-blue-200">
                  <th className="px-2 py-2 border">User Name</th>
                  <th className="px-4 py-2 border">User's Mobile</th>
                  <th className="px-4 py-2 border">Last Appointment Time</th>
                  <th className="px-4 py-2 border">Last Message Time</th>

                  <th className="px-4 py-2 border">Status</th>
                  <th className="px-4 py-2 border">Link</th>
                  <th className="px-4 py-2 border">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={`${
                      rowIndex === highlightedRow
                        ? "bg-yellow-500"
                        : "hover:bg-blue-100"
                    }`}
                  >
                    <td className="w-[15%] border px-2 py-2">
                      {row.user_name}
                    </td>
                    <td className="px-4 py-2 border">
                      <button
                        className="text-blue-500 cursor-pointer hover:underline"
                        onClick={() => {
                          handlePDataModal(row);
                        }}
                      >
                        {row.user_phone} <HiExternalLink />
                      </button>
                    </td>
                    <td className="w-[30%] border px-4 py-2">
                      {row.last_appointment_time ? (
                        <div className="flex items-center justify-center">
                          {formatToDateAndTime(row.last_appointment_time)}
                        </div>
                      ) : (
                        <p className="text-gray-500">Not available</p>
                      )}
                    </td>
                    <td className="px-4 py-2 border">
                      {row.last_message_time ? (
                        <div className="flex items-center justify-center">
                          {formatToDateAndTime(row.last_message_time)}
                        </div>
                      ) : (
                        <p className="text-red-500">Not available</p>
                      )}
                    </td>
                    <td className="px-4 py-2 border">
                      {row.chat_status ? (
                        <div className="flex items-center justify-center text-blue-600 capitalize">
                          {row.chat_status}
                        </div>
                      ) : (
                        <p className="text-red-500">Not available</p>
                      )}
                    </td>

                    {/* <td className="px-4 py-2 border">
                      <button
                        className="text-blue-500 cursor-pointer hover:underline"
                        onClick={() => {
                          createLinkAndOpenChat(row, rowIndex);
                        }}
                      >
                        Open chat <HiExternalLink />
                      </button>
                    </td>
                    <td className="px-4 py-2 border">
                      <p
                        className={`text-sm font-semibold capitalize ${
                          row.Chat_status === "active"
                            ? "text-[#8A2BE2]"
                            : row.Chat_status === "replied"
                            ? "text-green-400"
                            : row.Chat_status === "closed"
                            ? "text-black"
                            : row.Chat_status === "open"
                            ? "text-red-500"
                            : "text-green-700"
                        }`}
                      >
                        {row.Chat_status === "active"
                          ? "Live (Active)"
                          : row.Chat_status === "open"
                          ? "Live (Open)"
                          : row.Chat_status}
                      </p>
                      <button
                        className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                        onClick={() =>
                          handleChatStatusChange(
                            row.chat_id,
                            "closed",
                            rowIndex
                          )
                        }
                        disabled={isChatStatusSaveButtonDisabled[rowIndex]}
                      >
                        {isChatStatusSaveButtonDisabled[rowIndex]
                          ? "Please Wait..."
                          : "Close"}
                      </button>
                      <button
                        className="px-3 py-2 mt-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                        onClick={() =>
                          handleChatStatusChangeEdit(
                            row.chat_id,
                            row.Chat_status,
                            rowIndex
                          )
                        }
                      >
                        Edit
                      </button>
                    </td> */}
                    <td className="px-3 py-2 text-sm border">
                      <button
                        className="flex items-center gap-2 text-blue-500 cursor-pointer hover:underline"
                        onClick={() => {
                          redirectToFollowUpChatMessages(
                            row?.user_id,
                            row?.chat_id,
                            row?.chat_status,
                            rowIndex
                          );
                        }}
                      >
                        Open chat <HiExternalLink size={20} />
                      </button>
                    </td>

                    <td className="px-2 py-2 border">
                      {getActionButtonByChatStatus(row, rowIndex)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : isPageLoading ? (
          <div className="flex h-[70vh] items-center justify-center">
            <ClipLoader color={"blue"} size={60} />
          </div>
        ) : (
          <div className="flex h-full min-h-[60vh] w-full items-center justify-center">
            {startChatUserId ? (
              <div className="flex flex-col items-center justify-center gap-3">
                <p className="text-lg">
                  No followup chats found for{" "}
                  <span className="text-base text-blue-500">
                    {startChatMobileNumber}
                  </span>
                </p>
                <button
                  onClick={() =>
                    redirectToFollowUpChatMessages(
                      startChatUserId,
                      null,
                      null,
                      null
                    )
                  }
                  className="px-8 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                >
                  Start Chat
                </button>
              </div>
            ) : startChatMobileNumber ? (
              <p className="text-lg">
                No record found for{" "}
                <span className="text-base text-blue-500">
                  {startChatMobileNumber}
                </span>
              </p>
            ) : (
              <p className="text-lg font-semibold text-center">
                No FollowUp Chats Data available
              </p>
            )}
          </div>
        )}

        {data.length > 0 && (
          <div className="flex flex-col justify-center mt-10 md:mt-20 md:flex-row">
            <div className="flex justify-center md:ml-auto">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-flex w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Previous Page
              </button>
              {totalPages > 1 &&
                [
                  ...Array(
                    Math.min(numberOfButtons, totalPages - currentPage + 1)
                  ),
                ].map((_, index) => {
                  const pageNumber = currentPage + index;
                  return (
                    <button
                      key={index}
                      onClick={() => setCurrentPage(pageNumber)}
                      disabled={pageNumber > totalPages}
                      className={`ml-2 rounded-lg px-4 py-2 ${
                        currentPage === pageNumber
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                      }`}
                    >
                      {pageNumber}
                    </button>
                  );
                })}

              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="px-4 py-4 mx-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 md:px-8"
              >
                Next Page
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-flex w-5 h-5 transform -rotate-180"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="flex items-center justify-end mt-5 md:ml-auto md:mt-0">
              <input
                type="text"
                placeholder={`Go to page (1 - ${totalPages})`}
                value={filterPage}
                onChange={(e) => setFilterPage(e.target.value)}
                className="w-full py-2 pl-8 pr-2 text-sm font-medium text-gray-800 bg-white border border-gray-400 rounded-md outline-none"
              />
              <button
                className="px-4 py-3 mx-2 text-white bg-blue-500 rounded-lg cursor-pointer hover:bg-blue-300"
                onClick={handlePageSearch}
              >
                <FiSearch
                  className="w-4 h-4 rounded-lg cursor-pointer"
                  onClick={handlePageSearch}
                />
              </button>
            </div>
          </div>
        )}
      </div>
      <hr />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default FollowUpChatTool;
