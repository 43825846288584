import {
  useRecoilState,
  useRecoilTransaction_UNSTABLE,
  useSetRecoilState,
} from "recoil";
import { FaStar } from "react-icons/fa";
import "../../assets/css/scrollBar.css";
import { FaPencilAlt } from "react-icons/fa";
import {
  appointmentStatusState,
  dataState,
  highlightedRowState,
  mainLoaderState,
  selectedUserState,
  statusModalOpenState,
  appointmentIdState,
  isAppointmentHistoryModalOpenState,
  userMobileNumberState,
} from "../../store/appointment/atoms/appointmentAtomState";
import { parseISO, format } from "date-fns";
import { HiExternalLink } from "react-icons/hi";
import axios from "axios";
import { useState } from "react";
import UserDataPopup from "components/UserData/UserDataPopup";
import { getToken } from "utils/getToken";

import AppointmentHistoryModal from "./AppointmentHistoryModal";
import useDynamicHeight from "store/useDynamicHeight";

//PATIENT DETAILS IMPORTS
import AddPatientModal from "./AppointmentPatientDetails/AddPatientModal";
import AppointmentPatientDetail from "./AppointmentPatientDetails/AppointmentPatientDetail";

//APPOINTMENT DETAILS IMPORTS
import AppointmentDetailsModal from "./AppointmentDetails/AppointmentDetailsModal";

//Appointment VideoCALL Imports
import VideoCallModal from "./AppointmentVideoCall/VideoCallModal";
import { openVideoCallModalState } from "store/appointment/atoms/appointmentAtomState";

const AppointmentDesktopView = ({ handleEditClick, setModalContent }) => {
  const [highlightedRow, setHighlightedRow] =
    useRecoilState(highlightedRowState);
  const [appointmentStatus, setAppointmentStatus] = useRecoilState(
    appointmentStatusState
  );

  const [selectedUser, setSelectedUser] = useRecoilState(selectedUserState);
  const [appointmentId, setAppointmentId] = useRecoilState(appointmentIdState);
  const [mainLoader, setMainLoader] = useRecoilState(mainLoaderState);
  const [data, setData] = useRecoilState(dataState);
  const [isStatusModalOpen, setIsStatusModalOpen] =
    useRecoilState(statusModalOpenState);
  const [isPDataModalOpen, setIsPDataModalOpen] = useState(false);
  const [openVideoCallModal, setOpenVideoCallModal] = useRecoilState(
    openVideoCallModalState
  );

  const handlePDataModalClose = () => {
    setIsPDataModalOpen(false);
    setSelectedUser(null);
  };

  const handlePDataModal = (row) => {
    setSelectedUser(row.phone_number);
    // console.log(row.Chat_started_by);
    setIsPDataModalOpen(true);
  };

  function createLinkAndOpenPrescription(row, rowIndex) {
    if (!row.phone_number) {
      return "";
    }

    const url = `/admin/userdata?mobile_number=${row.phone_number}`;

    setHighlightedRow(rowIndex);
    setModalContent({ url });
  }

  const handleEditStatusClick = async (rowIndex) => {
    // console.log("rowIndex:", rowIndex);
    const user = data[rowIndex];
    setSelectedUser(user);
    try {
      // Make the API call to get the data for the selected user
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `consultation/appointment/fetch/?app_id=${user.id}`,
        {
          headers: {
            Authorization: `Token ${getToken()}`,
          },
        }
      );

      const userData = response.data.results[0];
      // console.log(response.data.results[0]);

      setAppointmentStatus(userData.appointment_status);

      setMainLoader(false);

      setIsStatusModalOpen(true);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [isAppointmentHistoryModalOpen, setIsAppointmentHistoryModalOpen] =
    useRecoilState(isAppointmentHistoryModalOpenState);
  const setAppointmentIdState = useSetRecoilState(appointmentIdState);
  const [userMobileNumber, setUserMobileNumber] = useRecoilState(
    userMobileNumberState
  );

  const handleAppointmentHistoryModal = useRecoilTransaction_UNSTABLE(
    ({ get, set }) =>
      (rowIndex) => {
        const user = get(dataState)[rowIndex];
        console.log(user);
        set(appointmentIdState, user.id);
        set(userMobileNumberState, user.phone_number);
        set(isAppointmentHistoryModalOpenState, true);
      }
  );

  const handleAppointmentHistoryModalClose = () => {
    setIsAppointmentHistoryModalOpen(false);
    setAppointmentIdState(null);
  };
  const { ref, isOverflowing } = useDynamicHeight([data]);

  const mapRatingToNumber = (rating) => {
    switch (rating) {
      case "very satisfied":
        return 3;
      case "satisfied":
        return 2;
      case "unsatisfied":
        return 1;
      default:
        return 0;
    }
  };

  // Function to map numbers back to rating strings
  const mapNumberToRating = (number) => {
    switch (number) {
      case 3:
        return "very satisfied";
      case 2:
        return "satisfied";
      case 1:
        return "unsatisfied";
      default:
        return "";
    }
  };

  const handleEditRatingClick = async (rowIndex, star) => {
    const updatedUser = data[rowIndex];
    const newRating = mapNumberToRating(star);
    const updatedData = data.map((item, index) =>
      index === rowIndex ? { ...item, rating: newRating } : item
    );

    setData(updatedData);
    setMainLoader(true);

    try {
      const updateData = JSON.stringify({
        appointment_id: updatedUser.id,
        rating: star,
      });

      const config = {
        method: "post",
        url: process.env.REACT_APP_API_URL + "consultation/appointment/update/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${getToken()}`,
        },
        data: updateData,
      };

      const response = await axios(config);
      console.log(response);
      setMainLoader(false);
    } catch (error) {
      console.error("Error updating rating:", error);
      setMainLoader(false);
    }
  };

  return (
    <>
      {isPDataModalOpen && (
        <UserDataPopup
          selectedUser={selectedUser}
          noSearch={true}
          handlePDataModalClose={handlePDataModalClose}
        />
      )}
      <table
        ref={ref}
        className={`custom-scrollbar ${
          isOverflowing ? "h-[74vh]" : "h-auto"
        } max-w-full table-auto overflow-scroll rounded-lg border-2 border-gray-600 bg-white text-sm shadow-md sm:block`}
      >
        <thead>
          <tr className="bg-blue-200">
            <th className="px-4 py-2 border">Doctor Name</th>
            <th className="px-4 py-2 border">Appointment Date</th>
            <th className="px-4 py-2 border">Type</th>
            <th className="px-4 py-2 border">User</th>
            <th className="px-4 py-2 border">Patient Details</th>
            {/* <th className="px-4 py-2 border">Payment-Subscription</th> */}
            <th className="px-4 py-2 border">Status</th>
            <th className="px-4 py-2 border">Video Consultation via App</th>
            <th className="px-4 py-2 border">History Form</th>
            <th className="px-4 py-2 border">Rating</th>
            <th className="px-4 py-2 border">Actions</th>
            {/* <th className="px-4 py-2 border">Appoinment Detail</th> */}
            {/* <th className="px-4 py-2 border">Copy History</th> */}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={`${
                  rowIndex === highlightedRow || row?.is_actionable
                    ? "bg-orange-200"
                    : "hover:bg-blue-100"
                }`}
              >
                <td className="px-4 py-2 border">{row.doctor_name}</td>
                <td className="px-4 py-2 border">
                  {row.scheduled_at ? (
                    <>
                      {format(parseISO(row.scheduled_at), "dd-MM-yyyy")}
                      <br />
                      {format(parseISO(row.scheduled_at), "HH:mm:ss")}
                    </>
                  ) : (
                    "N/A"
                  )}
                </td>

                <td className="px-4 py-2 border">{row.appointment_type}</td>

                <td className="px-4 py-2 border">
                  <div className="flex flex-col justify-center gap-3">
                    <button
                      className="flex items-center gap-2 text-blue-500 cursor-pointer hover:underline"
                      onClick={() => {
                        handlePDataModal(row);
                      }}
                    >
                      {row.phone_number} <HiExternalLink />
                    </button>

                    <p>{row?.name}</p>
                  </div>
                </td>

                <td className="w-4 px-4 py-2 border table-fixed">
                  <AppointmentPatientDetail
                    userMobile={row?.phone_number}
                    patientId={row?.patient_id}
                    appointmentIndex={rowIndex}
                    appointmentId={row?.id}
                  />
                </td>

                {/* <td className="px-4 py-2 border">
                  {`${
                    row.payment_amount ? `₹${row.payment_amount}` : "N/A"
                  } - ${row.subscription_name ? row.subscription_name : "N/A"}`}
                </td> */}

                <td className="px-4 py-2 border">
                  <p>{row.appointment_status}</p>
                  <button
                    className="self-center px-3 py-2 mt-2 text-xs text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                    onClick={() => {
                      setMainLoader(true);
                      handleEditStatusClick(rowIndex);
                    }}
                  >
                    Edit Status
                  </button>
                </td>

                <td className="flex flex-col flex-wrap px-4 py-2 border">
                  {row.video_consultation_allowed ? "YES" : "NO"}

                  {row.video_consultation_allowed && (
                    <button
                      onClick={() => {
                        setOpenVideoCallModal({
                          open: true,
                          appointmentId: row.id,
                        });
                      }}
                      className="px-3 py-2 mt-2 text-xs text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                    >
                      Join Call
                    </button>
                  )}
                </td>

                <td className="w-[150px] border px-1 py-2 ">
                  <button
                    className="flex items-center gap-2 text-blue-500 cursor-pointer hover:underline"
                    onClick={() => {
                      handleAppointmentHistoryModal(rowIndex);
                    }}
                  >
                    {row.patient_case_history_time ? (
                      <>
                        View
                        <HiExternalLink />
                      </>
                    ) : (
                      "N/A"
                    )}
                  </button>
                </td>

                <td className="w-[150px] border py-2 px-1">
                  <p>{row.rating ? row.rating : "Not Filled"}</p>

                  <div className="flex items-center gap-1">
                    {[1, 2, 3].map((star) => (
                      <FaStar
                        key={star}
                        className={`cursor-pointer ${
                          mapRatingToNumber(row.rating) >= star
                            ? "text-yellow-500"
                            : "text-gray-400"
                        }`}
                        onClick={() => {
                          handleEditRatingClick(rowIndex, star);
                        }}
                      />
                    ))}
                  </div>
                </td>

                <td
                  className="px-4 py-2 mr-2 text-blue-500 border cursor-pointer"
                  onClick={() => {
                    setMainLoader(true);
                    handleEditClick(rowIndex);
                  }}
                >
                  Edit All <FaPencilAlt className="relative left-3" />
                </td>
                {/* <td className="px-4 py-2 border">
                  <AppointmentDetails
                    appointmentDetails={row?.appointment_details}
                    appointmentId={row?.id}
                  />
                </td> */}
                {/* <td className="px-4 py-2 mr-2 text-blue-500 border cursor-pointer">
                  <CopyToClipboard
                    appointmentDetails={row?.appointment_details}
                    userMobile={row?.phone_number}
                    patientId={row?.patient_id}
                    appointmentIndex={rowIndex}
                  />
                </td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td className="px-4 py-2 border" colSpan="6">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <AddPatientModal />
      <AppointmentDetailsModal />
      <VideoCallModal />
      {isAppointmentHistoryModalOpen && (
        <AppointmentHistoryModal
          isOpen={isAppointmentHistoryModalOpen}
          closeModal={handleAppointmentHistoryModalClose}
        />
      )}
    </>
  );
};

export default AppointmentDesktopView;
