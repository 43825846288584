import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { getToken } from "utils/getToken";
import { useLocation } from "react-router-dom";
import Videocall from "views/admin/Videocall";

const App = () => {
  const location = useLocation();
  const [token, setToken] = useState(getToken());

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="auth/*" element={<AuthLayout />} />

      {/* Protected Routes */}
      {token ? (
        <>
          <Route path="admin/*" element={<AdminLayout />} />
        </>
      ) : (
        // Redirect to auth layout if the user is not authenticated
        <Route path="/*" element={<AuthLayout replace />} />
      )}
      {/* Redirect to the admin layout for authenticated users */}
      {token && (
        <Route path="/" element={<Navigate to="/admin/home" replace />} />
      )}
      {/* Redirect to the admin layout for authenticated users */}
      {token && <Route path="/video-consultation" element={<Videocall />} />}
    </Routes>
  );
};

export default App;
