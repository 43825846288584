import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { useRecoilState } from "recoil";
import axios from "axios";
import { toast } from "react-toastify";
import { getToken } from "utils/getToken";
import SyncLoader from "components/Loaders/SyncLoader";
import {
  openAddUpdateAppointmentDetailsModalState,
  isAppointmentDetailsUpdatedState,
} from "store/appointment/atoms/appointmentAtomState";
import { IoMdClose } from "react-icons/io";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};

const AppointmentDetailsModal = () => {
  const [
    openAddUpdateAppointmentDetailsModal,
    setOpenAddUpdateAppointmentDetailsModal,
  ] = useRecoilState(openAddUpdateAppointmentDetailsModalState);
  const [isAppointmentDetailsUpdated, setIsAppointmentDetailsUpdated] =
    useRecoilState(isAppointmentDetailsUpdatedState);
  const [updatingAppointmentDetails, setUpdatingAppointmentDetails] =
    useState(false);
  const [fetchingAppointmentDetails, setFetchingAppointmentDetails] =
    useState(false);

  const [formValues, setFormValues] = useState({
    last_weight:
      openAddUpdateAppointmentDetailsModal?.appointmentDetails?.last_weight ||
      "",
    last_weight_date:
      openAddUpdateAppointmentDetailsModal?.appointmentDetails
        ?.last_weight_date || "",
    hosptilaization_history:
      openAddUpdateAppointmentDetailsModal?.appointmentDetails
        ?.hosptilaization_history || "",
    problem:
      openAddUpdateAppointmentDetailsModal?.appointmentDetails?.problem || "",
  });

  const selectedAppointmentId =
    openAddUpdateAppointmentDetailsModal?.appointmentId;

  useEffect(() => {
    setFormValues({
      last_weight:
        openAddUpdateAppointmentDetailsModal?.appointmentDetails?.last_weight ||
        "",
      last_weight_date:
        openAddUpdateAppointmentDetailsModal?.appointmentDetails
          ?.last_weight_date || "",
      hosptilaization_history:
        openAddUpdateAppointmentDetailsModal?.appointmentDetails
          ?.hosptilaization_history || "",
      problem:
        openAddUpdateAppointmentDetailsModal?.appointmentDetails?.problem || "",
    });
  }, [openAddUpdateAppointmentDetailsModal.appointmentId]);

  const handleUpdateAppointmentDetails = async (e) => {
    e.preventDefault();

    if (selectedAppointmentId) {
      try {
        setUpdatingAppointmentDetails(true);

        const updatedAppointmentData = JSON.stringify({
          appointment_id: selectedAppointmentId,
          ...formValues,
        });

        const config = {
          method: "post",
          url:
            process.env.REACT_APP_API_URL + "consultation/appointment/update/",
          headers: headers,
          data: updatedAppointmentData,
        };

        // Update the data on the server using Axios
        const updateAppointmentResponse = await axios(config);

        if (updateAppointmentResponse.status === 200) {
          toast.success("Successfully saved appointment details!");
          setIsAppointmentDetailsUpdated(true);
          setOpenAddUpdateAppointmentDetailsModal({
            open: false,
            type: "",
            appointmentId: null,
          });
        }
      } catch (error) {
        console.error("UPDATE_APPOINTMENT_ERROR", error);
        toast.error(
          "Something went wrong while saving the appointment details!"
        );
      } finally {
        setUpdatingAppointmentDetails(false);
      }
    }
  };

  const getAppointmentById = async (appId) => {
    if (appId) {
      try {
        setFetchingAppointmentDetails(true);
        // Make the API call to get the data for the selected user
        const appointmentDetailsResponse = await axios.get(
          process.env.REACT_APP_API_URL +
            `consultation/appointment/fetch/?app_id=${appId}`,
          {
            headers,
          }
        );

        if (appointmentDetailsResponse.status === 200) {
          if (appointmentDetailsResponse.data?.results?.length > 0) {
            if (
              appointmentDetailsResponse.data?.results[0]?.appointment_details
            ) {
              const appointmentDetails =
                appointmentDetailsResponse.data?.results[0]
                  ?.appointment_details;
              console.log("Everything is fine setting state again...");
              setFormValues({
                last_weight: appointmentDetails?.last_weight || "",
                last_weight_date: appointmentDetails?.last_weight_date || "",
                hosptilaization_history:
                  appointmentDetails?.hosptilaization_history || "",
                problem: appointmentDetails?.problem || "",
              });
            }
          }
        }
      } catch (error) {
        console.error("APPOINTMENT_DETAILS_MODAL_APPOINTMENT_DATA", error);
      } finally {
        setFetchingAppointmentDetails(false);
      }
    }
  };

  useEffect(() => {
    if (selectedAppointmentId) {
      getAppointmentById(selectedAppointmentId);
    }
  }, [selectedAppointmentId]);

  console.log("AppointmentDetailsModal", formValues);
  return (
    <Dialog
      open={openAddUpdateAppointmentDetailsModal.open}
      onClose={() => {
        setOpenAddUpdateAppointmentDetailsModal({
          open: false,
          type: "",
          appointmentId: null,
        });
      }}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-[#000] opacity-20" aria-hidden="true" />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 overflow-y-auto">
        {/* Container to center the panel */}
        <div className="flex min-h-screen items-center justify-center p-4">
          {/* The actual dialog panel  */}
          {fetchingAppointmentDetails ? (
            <div>
              <SyncLoader size="10px" color="#525CEB" />
            </div>
          ) : (
            <Dialog.Panel className="mx-auto w-full max-w-md rounded md:min-w-[40%]">
              <div className="my-8 inline-block w-full bg-white p-6 shadow-xl">
                <div className="flex justify-end">
                  <button
                    onClick={() => {
                      setOpenAddUpdateAppointmentDetailsModal({
                        open: false,
                        type: "",
                        appointmentId: null,
                      });
                    }}
                    className="text-gray-600 hover:text-gray-800"
                  >
                    <IoMdClose size={25} />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="text-center text-lg font-medium leading-6 text-gray-900"
                >
                  Edit Appointment Details
                </Dialog.Title>
                <form
                  onSubmit={handleUpdateAppointmentDetails}
                  className="mt-4 space-y-4"
                >
                  <div className="w-full text-sm">
                    <p className="my-1.5 text-start font-semibold">
                      Last Weight(Kg):
                    </p>

                    <input
                      value={formValues.last_weight}
                      placeholder="Enter Last Weight"
                      onChange={(e) => {
                        setFormValues((prev) => {
                          return {
                            ...prev,
                            last_weight: parseFloat(e.target.value),
                          };
                        });
                      }}
                      type="number"
                      min="0"
                      step="any"
                      className="bg-transparent mt-2 w-full rounded border p-3 text-sm outline-none [&::-webkit-inner-spin-button]:appearance-none"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </div>

                  <div className="w-full text-sm">
                    <p className="my-1.5 text-start font-semibold">
                      Last Weight Date:
                    </p>

                    <input
                      type="date"
                      value={formValues.last_weight_date}
                      onChange={(e) => {
                        setFormValues((prev) => {
                          return {
                            ...prev,
                            last_weight_date: e.target.value,
                          };
                        });
                      }}
                      max={new Date().toISOString().split("T")[0]}
                      className={`w-full rounded-md border-gray-200 p-2`}
                    />
                  </div>

                  <div className="w-full text-sm">
                    <p className="my-1.5 text-start font-semibold">
                      Any Hospitalization History:
                    </p>
                    <textarea
                      value={formValues.hosptilaization_history}
                      onChange={(e) =>
                        setFormValues((prev) => {
                          return {
                            ...prev,
                            hosptilaization_history: e.target.value,
                          };
                        })
                      }
                      placeholder="Enter Patient Details"
                      className="w-full resize-none rounded-md border border-gray-400 px-3 py-1"
                    />
                  </div>

                  <div className="w-full text-sm">
                    <p className="my-1.5 text-start font-semibold">
                      Enter Problem:
                    </p>
                    <textarea
                      value={formValues.problem}
                      onChange={(e) =>
                        setFormValues((prev) => {
                          return {
                            ...prev,
                            problem: e.target.value,
                          };
                        })
                      }
                      placeholder="Enter Patient's Problem"
                      className="w-full resize-none rounded-md border border-gray-400 px-3 py-1"
                    />
                  </div>

                  <div className="mt-4">
                    {updatingAppointmentDetails ? (
                      <div>
                        <div className="rounded-md bg-blue-500 px-4 py-2 text-white">
                          <SyncLoader size="5px" />
                        </div>
                      </div>
                    ) : openAddUpdateAppointmentDetailsModal.type === "add" ? (
                      <button className="w-full rounded-md bg-blue-500 px-4 py-2 text-white">
                        Save Appointment Details
                      </button>
                    ) : (
                      <button className="w-full rounded-md bg-blue-500 px-4 py-2 text-white">
                        Update Appointment Details
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </Dialog.Panel>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default AppointmentDetailsModal;
